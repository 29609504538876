import { ajax, updateBlockModelCallback, stringifyParams } from 'OK/utils/vanilla';

function request(params) {
    return ajax(params).then(function (data) {
        updateBlockModelCallback(data);
    });
}

function nav(request) {
    if (typeof request === 'object') {
        request = request.url + '?' + stringifyParams(request.data);
    }

    window.navigateOnUrlFromJS(request);
}

    function openAttachment(payload) {
        var data;
        if (payload.photoId && payload.albumId) {
            data = {
                'cmd': "PopLayerPhoto",
                'st.layer.cmd': "PopLayerPhoto",
                'st.layer.type': payload.isGroup ? 'GROUP' : 'FRIEND',
                'st.layer.revNav': 'off',
                'st.layer.limitedUi': 'false',
                'st.layer.closeLayers': 'off',
                'st.layer.showNav': 'on',
                'st.layer.photoId': payload.photoId,
                'st.layer.navStartPhotoId': payload.photoId,
                'st.layer.sbd': 'off',
                'st.cmd': 'userMain'
            }
        } else {
            data = {
                'cmd': 'PopLayerPhoto',
                'st.layer.cmd': 'PopLayerPhoto',
                'st.layer.type': 'ATTACHMENT',
                'st.layer.revNav': 'off',
                'st.layer.limitedUi': 'false',
                'st.layer.closeLayers': 'off',
                'st.layer.showNav': 'on',
                'st.layer.mTTId': payload.messageId,
                'st.layer.mTTChatId': payload.chatId,
                'st.layer.photoId': payload.photoId,
                'st.layer.navStartPhotoId': payload.photoId,
                'st.layer.sbd': 'off',
                'st.layer.loadNavAsync': 'on',
                'st.cmd': 'userMain'
            };
        }
        nav({
            url: '/dk',
            data: data
        });
    }

    function openChatIcon(payload) {
        var isChat = payload.isChat;
        var photoId = payload.photoId;
        var data = {
            'cmd': "PopLayerPhoto",
            'st.layer.cmd': "PopLayerPhoto",
            'st.layer.type': isChat ? 'CHAT_ICON' : 'FRIEND',
            'st.layer.revNav': 'off',
            'st.layer.limitedUi': 'false',
            'st.layer.closeLayers': 'off',
            'st.layer.showNav': 'on',
            'st.layer.sbd': 'off',
            'st.cmd': 'userMain'
        };

        if (photoId) {
            data['st.layer.photoId'] = photoId;
            data['st.layer.navStartPhotoId'] = photoId;
        }

        if (payload.hideChangeChatIconWidget) {
            // не показывать кнопку «Изменить иконку чата»
            data['st.layer.hci'] = 'on';
        }

        if (isChat) {
            data['st.layer.convId'] = payload.convId;
        } else {
            data['st.layer.epm'] = 'off';
            data['st.layer.opl'] = 'off';
        }

        nav({
            url: '/dk',
            data: data
        });
    }

    function openPhotoPicker(payload) {
        request({
            url: '/dk',
            data: {
                'cmd': 'PopLayer',
                'st.layer.cmd': 'PopLayerMessageAttachPhotoPicker',
                'st.layer.objectId': payload.objectId,
                'st.layer.maxCount': payload.maxCount || -1,
                // на странице друга из-за особенностей фотоаттач-леера
                // неправльно отработает 'st.cmd': OK.getCurrentDesktopModelId()
                // (PersonalPhotosPagingTaskImpl: ((IFriendState) currentState).getFriendId(request);
                'st.cmd': 'userMain'
            }
        });
    }

    function on(payload) {
        switch (payload.name) {
            case 'open-photo-layer':
                openAttachment(payload);
                break;

            case 'open-conv-icon-layer':
                openChatIcon(payload);
                break;

            case 'open-photo-attach-layer':
                openPhotoPicker(payload);
                break;

            default:
            // TODO: обратотать ошибку
        }
    }

export default { on };

export { on };
